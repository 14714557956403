import type { ModerationStatus, Polyglot } from '../types';

export enum ProfileSocialPlatform {
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  TIKTOK = 'tiktok',
  DISCORD = 'discord',
  INSTAGRAM = 'instagram',
}

export interface ProfileLinkCode {
  linkCode: string;
}

export interface ProfileSocialLink {
  created: Date | string;
  username: string;
}

export enum AcknowledgementType {
  CREATOR_PICKS = 'creator_picks',
}

export interface Acknowledgement {
  type: AcknowledgementType;
  acceptedAt: Date;
}

export interface PlayerProfile {
  accountId: string;
  bio: Polyglot;
  social: Partial<Record<ProfileSocialPlatform, ProfileSocialLink>>;
  pinnedLinks: ProfileLinkCode[];
  maxRecentLinks: number | null;
  pickedLinks?: ProfileLinkCode[];
  headerImageMediaId?: string;
  headerImageMediaExpired?: boolean;
  profileImageMediaId?: string;
  profileImageMediaExpired?: boolean;
  mediaOnModeration?: {
    headerImageMediaId?: string;
    profileImageMediaId?: string;
  };
  moderation?: {
    id: string;
    status: ModerationStatus;
  };
  // Set to the most recently published version. Starts as null. Optional as there could be creators without versions yet.
  releaseVersion?: Date | null;
  acceptedAcknowledgements?: Acknowledgement[];
  previousBio?: Polyglot;
}

export interface ProfileRelease {
  /** primary key (profileId-version) */
  releaseId: string;
  /** a version that is set on release of non-media profile entities. */
  releaseVersion: Date;
  /** account that owns the profile */
  accountId: string;
  /** profile data associated with release */
  profile: PlayerProfile;
  /** a badger executionId associated with this release */
  moderationId: string;
  /** the result of the moderation and any provided reasons */
  result?: { success: boolean; reasons?: string[]; expired: boolean } | null;
  /** ddb ttl */
  ttl: number;
}

export interface ProfileLive extends PlayerProfile {
  recentLinks: ProfileLinkCode[];
  headerImageUrl?: string;
  coverImageUrl?: string;
  profileImageUrl?: string;
}

export enum ProfileUpdateType {
  CREATOR_UPDATED = 'CREATOR_UPDATED',
  CREATOR_CREATED = 'CREATOR_CREATED',
  CREATOR_DELETED = 'CREATOR_DELETED',
}

export interface ProfileUpdatesParams {
  accountId: string;
  type: ProfileUpdateType;
  updatingDateTime?: string;
}

export interface ProfileUpdatesMessage {
  type: ProfileUpdateType;
  date: string;
  accountId: string;
}
